'use client';

import { CustomLink, ScrollAnimation } from '@/ui';
import { Box, List, Skeleton } from '@mantine/core';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';
import sanitize from 'sanitize-html';
import useSWR from 'swr';
import classnames from './external-info.module.css';

type Currency = {
  items: {
    [key: string]: {
      code: string;
      nominal: string;
      rate: string;
    };
  };
};

export default function Currency() {
  const t = useTranslations();
  const { data: currency, isLoading } = useSWR<Currency>(['/main-page/rate']);

  const title = sanitize(
    t.rich('{date} dan <br /> MB valyuta kurslari', {
      br: () => <br />,
      date: dayjs().format('DD.MM.YYYY'),
    }) as string
  );

  return isLoading ? (
    <Skeleton height={84} radius="0" />
  ) : (
    <ScrollAnimation>
      <Box
        component={CustomLink}
        href="https://cbu.uz/oz/arkhiv-kursov-valyut"
        target="_blank"
        className={classnames.currency}
        aria-label={title}
      >
        <div>
          <h5 dangerouslySetInnerHTML={{ __html: title }}></h5>
          <h6>www.cbu.uz</h6>
        </div>
        <List>
          {Object?.values(currency?.items || {}).map((item) => {
            return (
              <List.Item key={item.code}>
                {item.nominal} {item.code} = {item.rate}
              </List.Item>
            );
          })}
        </List>
      </Box>
    </ScrollAnimation>
  );
}
